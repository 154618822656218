<template>
    <div v-if="getConfig('messages.enabled', true)">
        <v-row>
            <v-col cols="3" xl="2">
                <h2 class="primary--text text-center"> Mes messages </h2>

            </v-col>

            <v-col>
                <v-row v-if="false" justify="center">
                    <v-col cols="8">
                        <v-text-field class="mb-2 mr-5" placeholder="Recherche" hide-details rounded outlined dense>
                            <template v-slot:prepend-inner>
                                <v-btn class="mr-2" icon x-small>
                                    <v-icon> fas fa-search </v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-divider />

        <v-row :style="{ 'height': messageContainerHeight }">
            <!-- Discussions -->
            <v-col cols="3" xl="2">
                <v-list shaped>
                    <v-subheader>
                        Discussions

                        <v-spacer />

                        <CreateMessageGroupMenu @created="onConversationCreated" :activeConversations="conversations">
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on">
                                    <v-icon small> fas fa-plus </v-icon>
                                </v-btn>
                            </template>
                        </CreateMessageGroupMenu>
                    </v-subheader>

                    <v-list-item-group v-model="selectedConversationId" mandatory>
                        <v-list-item v-for="conversation of conversations" :value="conversation.id" :key="conversation.id">
                            <v-list-item-avatar class="contact-foreground white--text text-uppercase" size="32">
                                {{ conversation.users[0].name.slice(0, 1) }}
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title class="body-2"> {{ getConversationDisplayName(conversation) }} </v-list-item-title>

                                <v-list-item-subtitle v-if="conversation.message">
                                    <template v-if="conversation.message.isMe">
                                        Vous:
                                    </template>
                                    {{ conversation.message.content }}
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action v-show="false">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on">
                                            <v-icon small> fas fa-ellipsis-v </v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <LeaveConversationDialog :conversation="conversation">
                                            <template v-slot:activator="{ on }">
                                                <v-list-item v-on="on">
                                                    Quitter la conversation
                                                </v-list-item>
                                            </template>
                                        </LeaveConversationDialog>
                                    </v-list>
                                </v-menu>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>

            <v-divider vertical />

            <!-- Messages -->
            <v-col class="d-flex flex-column fill-height">
                <v-list ref="messageList" style="overflow-y: scroll">
                    <Message v-for="(message, i) of messages" :key="message.id" :messages="messages" :message="message" :index="i" @deleted="onMessageDeleted" @updated="onMessageUpdated" />
                    <template v-if="messages.length === 0">
                        <v-row justify="center" align="center" style="height: 200px" no-gutters>
                            <v-col>
                                <h3 class="text-center"> Aucun message </h3>
                            </v-col>
                        </v-row>
                    </template>
                </v-list>

                <v-divider />

                <v-row justify="center">
                    <v-col cols="8">
                        <v-textarea :disabled="!selectedConversationId" v-model="newMessage" rows="1" :placeholder="messages.length === 0 ? 'Envoyer un message' : 'Répondre'" background-color="grey lighten-4" rounded hide-details auto-grow>
                            <template v-slot:append-outer>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn @click="createNewMessage()" icon>
                                            <v-icon v-on="on"> fas fa-paper-plane </v-icon>
                                        </v-btn>
                                    </template>
                                    Envoyer
                                </v-tooltip>
                            </template>
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Message from '../components/messages/Message.vue';
import CreateMessageGroupMenu from '../components/messages/CreateMessageGroupMenu.vue';
import LeaveConversationDialog from '../components/messages/LeaveConversationDialog.vue';

export default {
    name: 'Messages',

    components: {
        Message,
        CreateMessageGroupMenu,
        LeaveConversationDialog
    },

    data: () => ({
        isLoading: false,
        newMessage: '',
        selectedConversationId: null,
        firstConversationFecth: true,

        conversations: [],
        conversationsCount: 0,
        conversationsTotalPages: 1,

        messages: [],
        messagesCount: 0,
        messagesTotalPages: 1,
        messagesCurrentPage: 1,

        messageContainerHeight: '200px'
    }),

    methods: {
        async createNewMessage() {
            if (!this.newMessage.trim()) {
                return;
            }

            try {
                this.isLoading = true;

                const body = {
                    newMessage: this.newMessage
                };
                const { success, message, err } = await this.repos.messaging.createMessage(this.selectedConversationId, body);
                if (success) {
                    this.newMessage = '';
                    this.messages.push(message);
                    this.$notify({
                        title: 'Succès',
                        text: 'Votre message à bien été envoyé',
                        type: 'success'
                    });
                    this.fetchConversations(this.selectedConversationId);
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de l\'envoi du message',
                    type: 'error'
                });
            } finally {
                this.isLoading = false;
            }
        },

        async fetchConversations(groupId) {
            try {
                this.isLoading = true;

                const query = {
                    include: 'users'
                };
                const { conversations, count, totalPages, err } = await this.repos.messaging.getConversations(query);
                if (err) {
                    throw new Error(err);
                }
                this.conversations = conversations;
                this.conversationsCount = count;
                this.conversationsTotalPages = totalPages;

                if (this.conversations.length > 0) {
                    if (groupId) {
                        this.selectedConversationId = groupId;
                    } else {
                        this.selectedConversationId = this.conversations[0].id;
                    }
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement de la page',
                    type: 'error'
                });
            } finally {
                this.isLoading = false;
            }
        },

        async fetchMessages() {
            try {
                this.isLoading = true;

                const query = {
                    page: this.messagesCurrentPage,
                    include: 'users'
                };
                const { messages, count, totalPages, err } = await this.repos.messaging.getMessages(this.selectedConversationId, query);
                if (err) {
                    throw new Error(err);
                }

                if (messages.length > 0) {
                    messages[messages.length - 1].last = true;
                }

                for (const message of messages) {
                    this.messages.unshift(message);
                }

                this.messagesCount = count;
                this.messagesTotalPages = totalPages;

                this.previousMessagesScrollHeight = this.$refs.messageList.$el.scrollHeight;

                if (this.firstConversationFecth) {
                    this.firstConversationFecth = false;
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            this.$refs.messageList.$el.scrollTop = this.$refs.messageList.$el.scrollHeight;
                        });
                    });
                } else {
                    this.$nextTick(() => {
                        const now = this.$refs.messageList.$el.scrollHeight - this.previousMessagesScrollHeight;
                        this.$refs.messageList.$el.scrollTop = now;
                    });
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement de la page',
                    type: 'error'
                });
            } finally {
                this.isLoading = false;
            }
        },

        onMessageDeleted(index) {
            this.messages.splice(index, 1);
        },

        onMessageUpdated(data) {
            this.messages[data.index].content = data.content;
        },

        onConversationCreated(data) {
            this.fetchConversations(data.groupId);
        },

        getConversationDisplayName(conversation) {
            return conversation.users
                .filter((m) => !m.isMe)
                .map((m) => {
                    if (m.id !== null) {
                        return `${m.name} ${m.firstname}`;
                    } else {
                        return 'Utilisateur supprimé';
                    }
                })
                .join(', ');
        },

        computeMessageContainerHeight() {
            const header = document.querySelector('main .v-toolbar');
            const footer = document.querySelector('#footer');

            const rect1 = header.getBoundingClientRect();
            const rect2 = footer.getBoundingClientRect();

            const height = window.innerHeight - (rect1.height + rect2.height) - 26;

            this.messageContainerHeight = `${height}px`;
        }
    },

    watch: {
        selectedConversationId() {
            this.messages = [];
            this.firstConversationFecth = true;
            this.messagesCurrentPage = 1;
            this.fetchMessages(this.selectedConversationId);
        }
    },

    created() {
        this.fetchConversations();

        this.$nextTick(() => {
            this.$refs.messageList.$el.addEventListener('scroll', () => {
                if (this.$refs.messageList.$el.scrollTop === 0) {
                    this.messagesCurrentPage++;
                    this.fetchMessages();
                }
            });

            this.computeMessageContainerHeight();
            window.addEventListener('resize', () => {
                this.computeMessageContainerHeight();
            });
        });
    }
};
</script>

<style lang="scss" scoped>
.no-resize::v-deep {
    textarea {
        resize: none;
        overflow: hidden;
    }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getConfig('messages.enabled', true))?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"3","xl":"2"}},[_c('h2',{staticClass:"primary--text text-center"},[_vm._v(" Mes messages ")])]),_c('v-col',[(false)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticClass:"mb-2 mr-5",attrs:{"placeholder":"Recherche","hide-details":"","rounded":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","x-small":""}},[_c('v-icon',[_vm._v(" fas fa-search ")])],1)]},proxy:true}],null,false,1713881427)})],1)],1):_vm._e()],1)],1),_c('v-divider'),_c('v-row',{style:({ 'height': _vm.messageContainerHeight })},[_c('v-col',{attrs:{"cols":"3","xl":"2"}},[_c('v-list',{attrs:{"shaped":""}},[_c('v-subheader',[_vm._v(" Discussions "),_c('v-spacer'),_c('CreateMessageGroupMenu',{attrs:{"activeConversations":_vm.conversations},on:{"created":_vm.onConversationCreated},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-plus ")])],1)]}}],null,false,79294269)})],1),_c('v-list-item-group',{attrs:{"mandatory":""},model:{value:(_vm.selectedConversationId),callback:function ($$v) {_vm.selectedConversationId=$$v},expression:"selectedConversationId"}},_vm._l((_vm.conversations),function(conversation){return _c('v-list-item',{key:conversation.id,attrs:{"value":conversation.id}},[_c('v-list-item-avatar',{staticClass:"contact-foreground white--text text-uppercase",attrs:{"size":"32"}},[_vm._v(" "+_vm._s(conversation.users[0].name.slice(0, 1))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.getConversationDisplayName(conversation))+" ")]),(conversation.message)?_c('v-list-item-subtitle',[(conversation.message.isMe)?[_vm._v(" Vous: ")]:_vm._e(),_vm._v(" "+_vm._s(conversation.message.content)+" ")],2):_vm._e()],1),_c('v-list-item-action',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-ellipsis-v ")])],1)]}}],null,true)},[_c('v-list',[_c('LeaveConversationDialog',{attrs:{"conversation":conversation},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_vm._v(" Quitter la conversation ")])]}}],null,true)})],1)],1)],1)],1)}),1)],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{staticClass:"d-flex flex-column fill-height"},[_c('v-list',{ref:"messageList",staticStyle:{"overflow-y":"scroll"}},[_vm._l((_vm.messages),function(message,i){return _c('Message',{key:message.id,attrs:{"messages":_vm.messages,"message":message,"index":i},on:{"deleted":_vm.onMessageDeleted,"updated":_vm.onMessageUpdated}})}),(_vm.messages.length === 0)?[_c('v-row',{staticStyle:{"height":"200px"},attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',[_c('h3',{staticClass:"text-center"},[_vm._v(" Aucun message ")])])],1)]:_vm._e()],2),_c('v-divider'),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-textarea',{attrs:{"disabled":!_vm.selectedConversationId,"rows":"1","placeholder":_vm.messages.length === 0 ? 'Envoyer un message' : 'Répondre',"background-color":"grey lighten-4","rounded":"","hide-details":"","auto-grow":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.createNewMessage()}}},[_c('v-icon',_vm._g({},on),[_vm._v(" fas fa-paper-plane ")])],1)]}}],null,false,2502052931)},[_vm._v(" Envoyer ")])]},proxy:true}],null,false,2825785858),model:{value:(_vm.newMessage),callback:function ($$v) {_vm.newMessage=$$v},expression:"newMessage"}})],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
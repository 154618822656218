<template>
    <v-menu v-model="isOpen" width="30%" :close-on-content-click="false" offset-x>
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-text-field ref="searchInput" v-model="search" placeholder="Nom, email" solo flat hide-details />

            <v-divider />

            <v-list-item-group v-model="selectedRecipients">
                <v-list-item v-for="recipient of recipients" :value="recipient" :key="recipient.email">
                    <v-list-item-avatar class="contact-foreground white--text text-uppercase" size="32">
                        {{ recipient.name.slice(0, 1) }}
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title class="body-2">
                            {{ recipient.name }} -
                            <span class="grey--text"> {{ recipient.job }} </span>
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            {{ recipient.email }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="recipients.length === 0">
                    <v-list-item-content>
                        <v-list-item-title class="body-2">
                            Aucun résultat
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: 'CreateMessageGroupMenu',

    props: {
        activeConversations: { type: Array, default: () => ([]) }
    },

    data: () => ({
        isOpen: false,
        search: '',
        selectedRecipients: []
    }),

    methods: {
        async createConversation() {
            if (!this.selectedRecipients) {
                return;
            }

            try {
                this.isLoading = true;

                const body = {
                    recipients: [this.selectedRecipients]
                };
                const { success, groupId, err } = await this.repos.messaging.createConversation(body);
                if (success) {
                    this.$emit('created', { groupId });
                    this.isOpen = false;
                    this.$notify({
                        title: 'Succès',
                        text: 'Conversation créée',
                        type: 'success'
                    });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la création de la conversation',
                    type: 'error'
                });
            } finally {
                this.isLoading = false;
            }
        }
    },

    computed: {
        recipients() {
            return this.getConfig('messages.partnersRecipients', [])
                .filter((r) => {
                    return (!this.search ||
                        (
                            r.name.toLowerCase().includes(this.search)
                            || r.email.toLowerCase().includes(this.search)
                        ))
                        && !this.activeConversations.some((c) => c.users.some((u) => u.id == r.id && u.type === r.type));
                });
        }
    },

    watch: {
        selectedRecipients() {
            this.createConversation();
        }
    }
};
</script>

<template>
    <div>
        <v-row v-if="message.last && index !== 0" no-gutters class="my-4 grey--text text--darken-1" align="center">
            <v-divider class="mr-4" />
            Anciens messages
            <v-divider class="ml-4" />
        </v-row>

        <v-hover v-slot="{ hover }">
            <v-list-item :class="{ 'grey lighten-4': hover }">
                <v-list-item-avatar v-if="!isPreviousMessageFromSameUser(message, index)" class="contact-foreground white--text text-uppercase mb-auto" size="32">
                    {{ message.user.name.slice(0, 1) }}
                </v-list-item-avatar>

                <v-list-item-avatar v-else size="32">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <span v-show="hover" class="grey--text text-caption" v-on="on">
                                {{ message.created | toTime(false) }}
                            </span>
                        </template>
                        {{ message.created | toDatetime() }}
                    </v-tooltip>
                </v-list-item-avatar>

                <v-list-item-content class="py-0">
                    <v-list-item-title class="body-2" v-if="!isPreviousMessageFromSameUser(message, index)">
                        {{ message.user.name }}

                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <span class="grey--text text-caption" v-on="on">
                                    {{  getDate(message.created) }}
                                </span>
                            </template>
                            {{ message.created | toDatetime() }}
                        </v-tooltip>
                    </v-list-item-title>

                    <v-list-item-subtitle class="user-content">
                        {{ message.content }}
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action v-if="message.isMe">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn small icon v-on="on">
                                <v-icon small> fas fa-ellipsis-h </v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <UpdateMessageDialog :message="message" :index="index" @updated="$emit('updated', $event)">
                                <template v-slot:activator="{ on }">
                                    <v-list-item v-on="on">
                                        Modifier
                                    </v-list-item>
                                </template>
                            </UpdateMessageDialog>

                            <DeleteMessageDialog :message="message" :index="index" @deleted="$emit('deleted', $event)">
                                <template v-slot:activator="{ on }">
                                    <v-list-item v-on="on">
                                        Supprimer
                                    </v-list-item>
                                </template>
                            </DeleteMessageDialog>
                        </v-list>
                    </v-menu>
                </v-list-item-action>
            </v-list-item>
        </v-hover>
    </div>
</template>

<script>
import { fr } from 'date-fns/locale';
import { format, isYesterday, differenceInDays } from 'date-fns';

import DeleteMessageDialog from './DeleteMessageDialog.vue';
import UpdateMessageDialog from './UpdateMessageDialog.vue';

export default {
    name: 'Message',

    props: {
        messages: { type: Array, required: true },
        message: { type: Object, required: true },
        index: { type: Number, required: true }
    },

    components: {
        DeleteMessageDialog,
        UpdateMessageDialog
    },

    methods: {
        isPreviousMessageFromSameUser(message, index) {
            if (message.last) {
                return false;
            }

            if (index === 0 || this.messages.length === 1) {
                return false;
            }

            return this.messages[index].userId === this.messages[index - 1].userId;
        },

        getDate(str) {
            const date = new Date(str);
            if (differenceInDays(new Date(), date) === 0) {
                if (isYesterday(date)) {
                    return 'Hier à ' + format(new Date(str), 'kk:mm', { locale: fr });
                } else {
                    return format(new Date(str), 'kk:mm', { locale: fr });
                }
            } else {
                return format(new Date(str), 'dd/MM/yyyy kk:mm', { locale: fr });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.user-content {
    white-space: pre-line;
}
</style>